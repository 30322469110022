import request from '@/utils/request'

export default {
  getRankingGoodLists(params) {
    return request({
      method: 'get',
      url: 'sales-rank',
      params
    })
  }
}

export function getClients(params) {
  return request({
    method: 'get',
    url: 'sales-rank/client',
    params
  })
}

export function getSuppliers(params) {
  return request({
    method: 'get',
    url: 'sales-rank/supplier',
    params
  })
}

export function getBrands(params) {
  return request({
    method: 'get',
    url: 'sales-rank/brand',
    params
  })
}
